import _index from "./utils/webkitGetAsEntryApi/index";
import _fallbackApi from "./utils/fallbackApi";
var exports = {};
const webkitGetAsEntryApi = _index;
const fallbackApi = _fallbackApi;
/**
 * Returns a promise that resolves to the array of dropped files (if a folder is
 * dropped, and browser supports folder parsing - promise resolves to the flat
 * array of all files in all directories).
 * Each file has .relativePath prop appended to it (e.g. "/docs/Prague/ticket_from_prague_to_ufa.pdf")
 * if browser supports it. Otherwise it's undefined.
 *
 * @param {DataTransfer} dataTransfer
 * @param {Function} logDropError - a function that's called every time some
 * folder or some file error out (e.g. because of the folder name being too long
 * on Windows). Notice that resulting promise will always be resolved anyway.
 *
 * @returns {Promise} - Array<File>
 */

exports = function getDroppedFiles(dataTransfer, _temp) {
  var _dataTransfer$items;

  let {
    logDropError = () => {}
  } = _temp === void 0 ? {} : _temp; // Get all files from all subdirs. Works (at least) in Chrome, Mozilla, and Safari

  if ((_dataTransfer$items = dataTransfer.items) != null && _dataTransfer$items[0] && "webkitGetAsEntry" in dataTransfer.items[0]) {
    return webkitGetAsEntryApi(dataTransfer, logDropError); // Otherwise just return all first-order files
  }

  return fallbackApi(dataTransfer);
};

export default exports;